import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab, fas);
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "/api";

export default function Form() {
  const [currentStep, setCurrentStep] = useState(0);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    city: "",
    position: "",
    company: "",
    industry: "",
    almaMater: "",
  });

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [currentStep]);

  const questions = [
    {
      key: "firstName",
      question: "What should we call you?",
      type: "text",
      placeholder: "Enter your first name",
    },
    {
      key: "lastName",
      question: "How about your last name?",
      type: "text",
      placeholder: "Enter your last name",
    },
    {
      key: "email",
      question: "What's your email address?",
      type: "email",
      placeholder: "Enter your email",
    },
    {
      key: "country",
      question: "Which country are you currently based?",
      type: "text",
      placeholder: "Enter your country",
    },
    {
      key: "city",
      question: "Which city?",
      type: "text",
      placeholder: "Enter your city",
    },
    {
      key: "position",
      question: "What's your current position?",
      type: "text",
      placeholder: "Enter your current position",
    },
    {
      key: "company",
      question: "Where do you work?",
      type: "text",
      placeholder: "Enter your company name",
    },
    {
      key: "industry",
      question: "What industry are you in?",
      type: "text",
      placeholder: "Enter your industry",
    },
    {
      key: "almaMater",
      question: "Where did you study?",
      type: "text",
      placeholder: "Enter your alma mater",
    },
  ];

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePrevious = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleNext = () => {
    const currentQuestion = questions[currentStep - 1];
    if (
      formData[currentQuestion.key] &&
      formData[currentQuestion.key].trim() !== ""
    ) {
      if (currentStep < questions.length) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      alert("Please enter a value before proceeding");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (currentStep < questions.length) {
        handleNext();
      } else {
        handleSubmit(e);
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const lastQuestion = questions[questions.length - 1];
    if (
      !formData[lastQuestion.key] ||
      formData[lastQuestion.key].trim() === ""
    ) {
      setPopupMessage("Please fill in all fields before submitting");
      setPopupVisible(true);
      return;
    }
    setPopupMessage("Submitting...");
    setPopupVisible(true);
    try {
      const response = await fetch(`${BACKEND_URL}/addNewNetwork`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const responseText = await response.text();
        console.error("Server response:", responseText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Document successfully written with ID:", data.id);
      setPopupMessage(
        "Done! Your information has been submitted successfully."
      );
    } catch (error) {
      console.error("Error details:", error);
      setPopupMessage(`Error submitting form: ${error.message}`);
    } finally {
      setTimeout(() => {
        setPopupVisible(false);
        setPopupMessage("");
        setFormData({});
        setCurrentStep(0);
      }, 3000);
    }
  };
  return (
    <div className="flex items-center justify-center min-h-screen">
      <style>
        {`
          input {
            color: white; /* Regular input text color */
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-text-fill-color: white;
            box-shadow: 0 0 0px 1000px transparent inset;
            transition: background-color 5000s ease-in-out 0s;
          }
        `}
      </style>
      <div className="max-w-2xl w-full mx-auto text-center p-4">
        {currentStep === 0 ? (
          <>
            <h1 className="text-4xl text-white mb-2">Series</h1>
            <p className="text-lg text-white mb-10">Join Vera's network</p>
            <button
              onClick={() => setCurrentStep(1)}
              className="bg-white text-black px-4 py-2 rounded-full text-lg hover:bg-gray-300 flex items-center justify-center mx-auto"
            >
              Get started
              <FontAwesomeIcon
                icon="fa-solid fa-arrow-right"
                className="ml-2 px-2"
              />
            </button>
          </>
        ) : (
          <div className="text-left text-white">
            <div className="mb-4 text-sm text-gray-500">
              {currentStep}/{questions.length}
            </div>
            <h2 className="text-2xl font-normal text-white mb-10">
              {questions[currentStep - 1].question}
            </h2>
            <input
              ref={inputRef}
              type={questions[currentStep - 1].type}
              name={questions[currentStep - 1].key}
              value={formData[questions[currentStep - 1].key] || ""}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              className="w-full bg-transparent border-b border-gray-500 pb-2 focus:outline-none text-xl"
              placeholder={questions[currentStep - 1].placeholder}
            />
            <div className="mt-5 flex justify-between">
              {currentStep > 1 && (
                <button
                  onClick={handlePrevious}
                  className="bg-gray-800 text-white px-4 py-2 rounded-full hover:bg-gray-700"
                >
                  Previous
                </button>
              )}
              <div className="flex-1" />
              {currentStep < questions.length ? (
                <button
                  onClick={handleNext}
                  className="bg-white text-black px-4 py-2 rounded-full hover:bg-gray-200 ml-auto"
                >
                  Next
                </button>
              ) : (
                <button
                  onClick={handleSubmit}
                  className="bg-white text-black px-4 py-2 rounded-full hover:bg-gray-200 ml-auto"
                >
                  Submit
                </button>
              )}
            </div>
          </div>
        )}
        {popupVisible && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white text-black px-6 py-4 rounded-lg shadow-lg">
              {popupMessage}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
