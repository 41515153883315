import React, { useEffect, useRef } from "react";

function Background() {
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (!wrapperRef.current) return;

    wrapperRef.current.style.setProperty("--color-a", "#0D00A4");
    wrapperRef.current.style.setProperty("--color-b", "#04052E");
    wrapperRef.current.style.setProperty("--color-c", "#140152");

  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center -z-10 overflow-hidden">
      <div
        className="absolute w-[205vw] h-[205vh] bg-[conic-gradient(from_0deg_at_50%_50%,_rgba(0,243,105,0.9)_0%,_rgba(8,35,18,0.93)_100%)] animate-spin-slow"
        style={{ animation: "spin 40s linear infinite" }}
      ></div>
      <div
        className="absolute inset-0 bg-repeat bg-[length:300px_300px] pointer-events-none"
        style={{
          backgroundImage: "url('https://guidehuman.co/media/589d51ce.webp')",
        }}
      ></div>
    </div>
  );
}

export default Background;
