import React from "react";
import Form from "./Form";
// import Header from "./Header";
// import Footer from "./Footer";
import Background from "./Background";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Background />
      <Form />
    </div>
  );
}

export default App;
